import IMask from 'imask';
export default function FormValidation() {
	const form = document.getElementById('form');
	const buttonSubmit = document.getElementById('sendForm');
	const username = document.getElementById('username');
	const email = document.getElementById('email');
	const phone = document.getElementById('phone');
	const checkResumeSwitch = document.getElementById('resume');
	const resumeLink = document.getElementById('resumeLink');
	const resumeFile = document.getElementById('uploadCaptureInputFile');
	const companyName = document.getElementById('companyName');
	const jobTitle = document.getElementById('jobTitle');
	const policy = document.getElementById('policy');

	if (form) {
		if (phone) {
			let maskOptions = {
				mask: '+7 000 000 00 00',
				lazy: false,
			};
			new IMask(phone, maskOptions);
		}

		// checkResumeSwitch.addEventListener('change', () => {
		// 	if (checkResumeSwitch.checked) {
		// 		const formFieldName = companyName.parentElement;
		// 		const formFieldTitle = jobTitle.parentElement;
		// 		companyName.value = '';
		// 		jobTitle.value = '';
		// 		formFieldName.classList.remove('success');
		// 		formFieldName.classList.remove('error');
		// 		formFieldTitle.classList.remove('success');
		// 		formFieldTitle.classList.remove('error');
		// 	} else {
		// 		const formFieldLink = resumeLink.parentElement;
		// 		resumeLink.value = '';
		// 		resumeFile.value = '';
		// 		document.getElementById('rest-upload').click();
		// 		formFieldLink.classList.remove('success');
		// 		formFieldLink.classList.remove('error');
		// 	}
		// });

		const checkUsername = (checkValid) => {
			let valid = false;

			const min = 2,
				max = 100;

			const usernameEl = username.value.trim();

			if (!isRequired(usernameEl)) {
				if (!checkValid) {
					showError(username, 'ФИО не может быть пустым');
				}
			} else if (!isBetween(usernameEl.length, min, max)) {
				if (!checkValid) {
					showError(username, `ФИО должно быть между ${min} и ${max} символами.`);
				}
			} else {
				showSuccess(username);
				valid = true;
			}
			return valid;
		};

		const checkEmail = (checkValid) => {
			let valid = false;
			const emailEl = email.value.trim();
			if (!isRequired(emailEl)) {
				if (!checkValid) {
					showError(email, 'Email не может быть пустым.');
				}
			} else if (!isEmailValid(emailEl)) {
				if (!checkValid) {
					showError(email, 'Email не верно указан.');
				}
			} else {
				showSuccess(email);
				valid = true;
			}
			return valid;
		};
		const checkPhone = (checkValid) => {
			let valid = false;
			const phoneEl = phone.value;
			if (!isRequired(phoneEl)) {
				if (!checkValid) {
					showError(phone, 'Телефон не может быть пустым.');
				}
			} else if (!isPhoneValid(phoneEl)) {
				if (!checkValid) {
					showError(phone, 'Не верно указан телефон.');
				}
			} else {
				showSuccess(phone);
				valid = true;
			}
			return valid;
		};
		const isPhoneValid = (phone) => {
			const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
			return re.test(phone.trim().replace(/\s/g, ''));
		};
		const isEmailValid = (email) => {
			const re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		};
		//check policy is valid
		const checkResume = (checkValid) => {
			let valid = false;
			const fileResume = resumeFile.files;
			const resumeLinkEl = resumeLink.value;
			const switchResume = checkResumeSwitch.checked;
			const companyNameEl = companyName.value;
			const jobTitleEl = jobTitle.value;
			if (switchResume) {
				if (!isRequired(resumeLinkEl) && fileResume.length === 0) {
					if (!checkValid) {
						showError(resumeLink, 'Укажите ссылку или прикрепите файл с резюме');
					}
				} else {
					showSuccess(resumeLink);
					valid = true;
				}
			} else {
				if (jobTitleEl.length === 0) {
					if (!checkValid) {
						showError(jobTitle, 'Введите название');
					}
				}
				if (companyNameEl.length === 0) {
					if (!checkValid) {
						showError(companyName, 'Введите название');
					}
				}
				if (companyNameEl.length > 0) {
					showSuccess(companyName);
					valid = true;
				}
				if (jobTitleEl.length > 0) {
					showSuccess(jobTitle);
					valid = true;
				}
			}
			return valid;
		};

		const checkPolicy = (checkValid) => {
			let valid = false;
			const policyEl = policy.checked;
			if (policyEl) {
				showSuccess(policy);
				valid = true;
			} else {
				if (!checkValid) {
					showError(policy, '');
				}
			}
			return valid;
		};

		const isRequired = (value) => value !== '';
		const isBetween = (length, min, max) => !(length < min || length > max);

		const showError = (input, message) => {
			// get the form-field element
			const formField = input.parentElement;
			// add the error class
			formField.classList.remove('success');
			formField.classList.add('error');

			// show the error message
			const error = formField.querySelector('small');
			error.textContent = message ? message : '';
		};

		const showSuccess = (input) => {
			// get the form-field element
			const formField = input.parentElement;

			// remove the error class
			formField.classList.remove('error');
			formField.classList.add('success');

			// hide the error message
			const error = formField.querySelector('small');
			error.textContent = '';
		};

		form.addEventListener('submit', function (e) {
			// prevent the form from submitting
			e.preventDefault();

			// validate fields

			// submit to the server if the form is valid
			if (isFormValid) {
				var formData = new FormData(this);
				fetch(this.attributes.action.value, { method: this.attributes.method.value, body: formData });
				document.getElementById('drawer-close').click();
			}
		});

		function isFormValid() {
			let isUsernameValid = checkUsername(true),
				isEmailValid = checkEmail(true),
				isPhoneValid = checkPhone(true),
				isResumeValid = checkResume(true),
				isCheckPolicy = checkPolicy(true),
				isFormValid = isUsernameValid && isEmailValid && isPhoneValid && isResumeValid && isCheckPolicy;
			if (isFormValid) {
				form.classList.add('success');
				buttonSubmit.disabled = false;
				return true;
			} else {
				form.classList.remove('success');
				buttonSubmit.disabled = true;
				return false;
			}
		}

		const debounce = (fn, delay = 200) => {
			let timeoutId;
			return (...args) => {
				// cancel the previous timer
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				// setup a new timer
				timeoutId = setTimeout(() => {
					fn.apply(null, args);
				}, delay);
			};
		};

		form.addEventListener(
			'input',
			debounce(function (e) {
				switch (e.target.id) {
					case 'username':
						checkUsername();
						break;
					case 'email':
						checkEmail();
						break;
					case 'phone':
						checkPhone();
						break;
					case 'uploadCaptureInputFile':
						checkResume();
						break;
					case 'resumeLink':
						checkResume();
						break;
					case 'policy':
						checkPolicy();
						break;
					case 'companyName':
						checkResume();
						break;
					case 'jobTitle':
						checkResume();
						break;
				}
				isFormValid();
			})
		);
	}
}
