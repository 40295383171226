import IMask from 'imask';

export default function FormValidationCall() {
	const form = document.getElementById('form-call');
	const username = document.getElementById('req-username');
	const email = document.getElementById('req-email');
	const phone = document.getElementById('req-phone');
	const errBox = document.querySelector('.err-box');
	const modalSuccess = document.querySelector('.modal-success');
	let isName = true;
	let isEmail = true;
	let isPhone = true;

	if (form) {
		if (phone) {
			let maskOptions = {
				mask: '+7 000 000 00 00',
				lazy: false,
			};
			new IMask(phone, maskOptions);
		}

		const showError = (input, message) => {
			// get the form-field element
			const formField = input.parentElement;
			// add the error class
			formField.classList.remove('success');
			formField.classList.add('error');

			// show the error message
			const error = formField.querySelector('small');
			error.textContent = message ? message : '';
		};

		const checkUsername = (checkValid) => {
			let valid = false;

			const min = 2,
				max = 100;

			const usernameEl = username.value.trim();

			if (!isRequired(usernameEl)) {
				if (!checkValid) {
					showError(username, 'Пожалуйста, заполните все обязательные поля');
					isName = false;
					errBox.querySelector('.all-err').style.display = 'block';
					errBox.style.display = 'block';
				}
			} else if (!isBetween(usernameEl.length, min, max)) {
				if (!checkValid) {
					showError(username, `Имя должно быть между ${min} и ${max} символами.`);
				}
			} else {
				showSuccess(username);
				isName = true;
				valid = true;
			}
			return valid;
		};

		const checkEmail = (checkValid) => {
			let valid = false;
			const emailEl = email.value.trim();
			if (!isRequired(emailEl)) {
				if (!checkValid) {
					showError(email, 'Пожалуйста, заполните все обязательные поля');
					errBox.querySelector('.all-err').style.display = 'block';
					isEmail = false;
					errBox.style.display = 'block';
				}
			} else if (!isEmailValid(emailEl)) {
				if (!checkValid) {
					showError(email, 'Email не верно указан.');
					errBox.querySelector('.email-err').style.display = 'block';
					isEmail = false;
					errBox.style.display = 'block';
				}
			} else {
				showSuccess(email);
				errBox.querySelector('.email-err').style = '';
				isEmail = true;
				valid = true;
			}
			return valid;
		};

		const checkPhone = (checkValid) => {
			let valid = false;
			const phoneEl = phone.value;
			if (phoneEl === '+7 ___ ___ __ __') {
				showSuccess(phone);
				errBox.querySelector('.phone-err').style = '';
				isPhone = true;
				valid = true;
			} else if (!isPhoneValid(phoneEl)) {
				if (!checkValid) {
					showError(phone, 'Не верно указан телефон.');
					errBox.querySelector('.phone-err').style.display = 'block';
					isPhone = false;
					errBox.style.display = 'block';
				}
			} else {
				showSuccess(phone);
				errBox.querySelector('.phone-err').style = '';
				isPhone = true;
				valid = true;
			}
			return valid;
		};

		const isPhoneValid = (phone) => {
			const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
			return re.test(phone.trim().replace(/\s/g, ''));
		};
		const isEmailValid = (email) => {
			const re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		};

		const isRequired = (value) => value !== '';
		const isBetween = (length, min, max) => !(length < min || length > max);

		const showSuccess = (input) => {
			// get the form-field element
			const formField = input.parentElement;

			// remove the error class
			formField.classList.remove('error');
			formField.classList.add('success');

			// hide the error message
			const error = formField.querySelector('small');
			error.textContent = '';
		};

		form.addEventListener('submit', function (e) {
			// prevent the form from submitting
			e.preventDefault();

			// validate fields

			// submit to the server if the form is valid
			if (isFormValid()) {
				// document.getElementById('drawer-close').click()
				var formData = new FormData(this);
				fetch(this.attributes.action.value, { method: this.attributes.method.value, body: formData });
				modalSuccess.style.height = '100%';
				form.style.height = '0';
			}
		});

		function isFormValid() {
			let isUsernameValid = checkUsername(false),
				isEmailValid = checkEmail(false),
				// isPhoneValid = checkPhone(true),
				isFormValid = isUsernameValid && isEmailValid;
			// && isPhoneValid
			if (isFormValid) {
				form.classList.add('success');
				return true;
			} else {
				form.classList.remove('success');
				return false;
			}
		}

		const debounce = (fn, delay = 200) => {
			let timeoutId;
			return (...args) => {
				// cancel the previous timer
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				// setup a new timer
				timeoutId = setTimeout(() => {
					fn.apply(null, args);
				}, delay);
			};
		};

		form.addEventListener(
			'input',
			debounce(function (e) {
				switch (e.target.id) {
					case 'req-username':
						checkUsername();
						break;
					case 'req-email':
						checkEmail();
						break;
					case 'req-phone':
						checkPhone();
						break;
				}
				if (isName && isEmail && isPhone) {
					errBox.style.display = 'none';
				}
				if (isName && isEmail) {
					errBox.querySelector('.all-err').style = '';
				}
				// isFormValid()
			})
		);
	}
}
