export default function Popup() {
    const formSend = document.getElementById('sendForm');
    const form = document.getElementById('form');

    const popup = new Popup();
    popup
        .open('cookie', 2000);

    if(formSend) {
        formSend.addEventListener('click', () => {
            if(form.classList.contains('success')) {
                setTimeout( () => popup.open('send-form', 200), 300)

            }
        });
    }
    function Popup(options = {}) {
        const DEFAULT_OPTIONS = {
            openClass: 'popup--active',
            dataClose: 'data-popup-close',
            dataOpen: 'data-popup-open',
            dataTarget: 'data-popup',
        };

        options = Object.assign(options, DEFAULT_OPTIONS);
        options = Object.freeze(options);

        const getEl = name => document.querySelector(`[${options.dataTarget}='${name}']`);
        const getName = el => el.getAttribute(options.dataTarget);

        const setHidden = (el, value) => {
            el.setAttribute('aria-hidden', value ? 'true' : 'false');
        }

        const events = { 'open': [], 'close': [], };

        const fireEvent = (type, name, initiatorEvent) => {
            if(events[type].length === 0) return;
            events[type].forEach(callback => callback(name, initiatorEvent));
        }

        const isActive = el => el.classList.contains(options.openClass);

        this.open = (name, delay = 0) => {
            const el = getEl(name);
            if(!el || isActive(el)) return;

            setTimeout(() => {
                if(isActive(el)) return;
                const eventName = 'open_of_' + name;
                const initiatorEvent = this[eventName];
                if(this[eventName]) delete this[eventName];

                fireEvent('open', name, initiatorEvent);
                setHidden(el, false);
                setTimeout(() => el.classList.add(options.openClass), 50);
            }, delay);

            return this;
        }

        this.close = name => {
            const el = getEl(name);
            if(!el || !isActive(el)) return;

            const endFadeOut = e => {
                setHidden(el, true);
                el.removeEventListener('transitionend', endFadeOut);
            }

            const eventName = 'close_of_' + name;
            const initiatorEvent = this[eventName];
            if(this[eventName]) delete this[eventName];

            fireEvent('close', name, initiatorEvent);
            el.addEventListener('transitionend', endFadeOut);
            el.classList.remove(options.openClass);

            return this;
        }

        this.on = (type, callback) => {
            if(!events[type] && typeof callback !== 'function') return;
            events[type].push(callback);
            return this;
        }

        const dataOpen = e => {
            const initiatorEl = e.target.closest(`[${options.dataOpen}]`);
            if(!initiatorEl) return;
            const name = initiatorEl.getAttribute(options.dataOpen);
            this['open_of_' + name] = e;
            this.open(name);
        }

        const dataClose = e => {
            const initiatorEl = e.target.closest(`[${options.dataClose}]`);
            if(!initiatorEl) return;
            let name = initiatorEl.getAttribute(options.dataClose);
            if (!name) {
                const el = e.target.closest(`[${options.dataTarget}]`);
                name = getName(el);
            }

            if(!name) return;
            this['close_of_' + name] = e;
            this.close(name);
        };

        document.addEventListener('click', dataOpen);
        document.addEventListener('click', dataClose);

        if(!new.target) return this;
    }
}
