import Swiper, { Navigation, Pagination, Scrollbar, Grid } from 'swiper';

export default function WorkSlider() {
	const sliderWrap = document.querySelector('.vacancies-block__swiper');
	if (sliderWrap) {
		Swiper.use([Navigation, Pagination, Scrollbar, Grid]);
		const pagination = sliderWrap.querySelector('.swiper-pagination');
		const paginationInnerHtml = pagination.innerHTML;
		const cardsWrap = document.getElementById('vacancies-cards');
		const sliderWrapClassList = sliderWrap.classList.value;
		const cardsWrapClassList = cardsWrap.classList.value;

		let vacanciesSlider = false;

		const initVacanciesSlider = () => {
			cardsWrap.classList.add('swiper-wrapper');
			sliderWrap.classList.add('swiper');
			vacanciesSlider = new Swiper('.vacancies-block__swiper', {
				grid: {
					rows: 2,
				},
				slidesPerView: 'auto',
				draggable: true,
				spaceBetween: 30,
				pagination: {
					el: '.vacancies-block__swiper .swiper-pagination',
					clickable: true,
					renderBullet(index, className) {
						return `<div class="pagination-dot ${className}"></div>`;
					},
				},
			});
		};

		const destroyVacanciesSlider = () => {
			vacanciesSlider.destroy();
			vacanciesSlider = false;
			sliderWrap.classList.value = sliderWrapClassList;
			cardsWrap.classList.value = cardsWrapClassList;
			pagination.innerHTML = paginationInnerHtml;
		};

		window.addEventListener('resize', () => {
			if (window.innerWidth < 768) {
				if (!vacanciesSlider) initVacanciesSlider();
			} else {
				if (vacanciesSlider) destroyVacanciesSlider();
			}
		});

		if (window.innerWidth < 768) initVacanciesSlider();
	}
}
