export default function Drawer() {
    const nav = document.getElementById('nav');
    const callReq = document.getElementById('call-req');
    const callBtn = document.querySelector('.footer-page__connect-link');
    const callBtnHeader = document.querySelector('.site-navbar__application > button');
    const callCloseBtn = document.querySelector('.call-req-close');
    const sendBtn = document.getElementById('send-button');
    const sendBtnMobile = document.getElementById('send-button-mobile');
    const drawerCloseBtn = document.getElementById('drawer-close');
    const drawerCancelBtn = document.getElementById('drawer-cancel');
    const overlay = document.getElementById('overlay');
    const body = document.getElementsByTagName('body');
    const popupSend = document.getElementById('popup-send');
    let clickNotButton = sendBtn
    function removeClasses() {
        nav.classList.remove('active');
        overlay.classList.remove('active');
        body[0].classList.remove('not-scroll');
    }
    function addClasses() {
        nav.classList.add('active');
        overlay.classList.add('active');
        body[0].classList.add('not-scroll');
    }
    if(sendBtn) {
        sendBtn.addEventListener('click', () => {
            clickNotButton = sendBtn
            addClasses()
        });
    }
    if(drawerCancelBtn) {
        drawerCancelBtn.addEventListener('click', () => {
            removeClasses()
        });
    }
    if(drawerCloseBtn) {
        drawerCloseBtn.addEventListener('click', () => {
            removeClasses()
        });
    }
    if(sendBtnMobile) {
        sendBtnMobile.addEventListener('click', () => {
            clickNotButton = sendBtnMobile
            addClasses()
        });
    }

    if (callBtnHeader) {
        callBtnHeader.addEventListener('click', () => {
            clickNotButton = callBtnHeader
            addClassesCall()
        });
    }

    if(callBtn) {
        callBtn.addEventListener('click', (event) => {
            event.preventDefault()
            clickNotButton = callBtn
            addClassesCall()
        });
    }
    if(callCloseBtn) {
        callCloseBtn.querySelector('button').addEventListener('click', () => {
            clickNotButton = callBtn
            removeClassesCall()
        });
    }
    function removeClassesCall() {
        callReq.classList.remove('active');
        callCloseBtn.classList.remove('active');
        overlay.classList.remove('active');
        body[0].classList.remove('not-scroll');
    }
    function addClassesCall() {
        callReq.classList.add('active');
        overlay.classList.add('active');
        callCloseBtn.classList.add('active');
        body[0].classList.add('not-scroll');
    }

    window.addEventListener('click', function(e){
        if(clickNotButton && document.getElementById('nav')) {
            const notClick = !clickNotButton.contains(e.target)
            const notClickPopup = !popupSend.contains(e.target)
            const clickNotDrawer = !document.getElementById('nav').contains(e.target);
            if (notClickPopup && notClick && clickNotDrawer && nav.classList.contains('active')){
                removeClasses()
            }
        }
        if(clickNotButton && document.getElementById('call-req')) {
            const notClick = !clickNotButton.contains(e.target)
            const clickNotDrawer = !document.getElementById('call-req').contains(e.target);
            if (notClick && clickNotDrawer && callReq.classList.contains('active') && e.target !== callCloseBtn){
                removeClassesCall();
            }
        }
    });
}
