import Swiper, { Navigation, Pagination, Scrollbar, Grid } from 'swiper';

export default function EmployeesSlider() {
	const sliderWrap = document.querySelector('.career-block__slider');
	if (sliderWrap) {
		Swiper.use([Navigation, Pagination, Scrollbar, Grid]);
		const pagination = sliderWrap.querySelector('.swiper-pagination');
		const paginationInnerHtml = pagination.innerHTML;
		const cardsWrap = document.getElementById('career-cards');
		const sliderWrapClassList = sliderWrap.classList.value;
		const cardsWrapClassList = cardsWrap.classList.value;

		let employeeSlider = false;

		const initEmployeeSlider = () => {
			cardsWrap.classList.add('swiper-wrapper');
			sliderWrap.classList.add('swiper');
			employeeSlider = new Swiper('.career-block .swiper', {
				slidesPerView: 'auto',
				direction: 'horizontal',
				draggable: true,
				spaceBetween: 20,
				pagination: {
					el: '.career-block__slider .swiper-pagination',
					clickable: true,
					renderBullet(index, className) {
						return `<div class="pagination-dot ${className}"></div>`;
					},
				},
			});
		};

		const destroyEmployeeSlider = () => {
			employeeSlider.destroy();
			employeeSlider = false;
			sliderWrap.classList.value = sliderWrapClassList;
			cardsWrap.classList.value = cardsWrapClassList;
			pagination.innerHTML = paginationInnerHtml;
		};

		window.addEventListener('resize', () => {
			if (window.innerWidth < 768) {
				if (!employeeSlider) initEmployeeSlider();
			} else {
				if (employeeSlider) destroyEmployeeSlider();
			}
		});

		if (window.innerWidth < 768) initEmployeeSlider();
	}
}
