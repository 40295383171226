import WorkSlider from './components/workSlider';
import Menu from './components/menu';
import Select from './components/select';
import Drawer from './components/drawer';
import ScrollTop from './components/scrollTop';
import ActionButtons from './components/actionButtons';
import Upload from './components/upload';
import Popup from './components/popup';
import FormActions from './components/formActions';
import FormValidation from './components/formValidation';
import FormValidationCall from './components/formValidationCall';
import EmployeesSlider from './components/employeesSlider';
import { Howl, Howler } from 'howler';

(function () {
	document.addEventListener('DOMContentLoaded', function () {
		Menu();
		Select();
		Drawer();
		ScrollTop();
		ActionButtons();
		Upload();
		WorkSlider();
		Popup();
		FormActions();
		FormValidation();
		FormValidationCall();
		EmployeesSlider();
	});
})();
