export default function Select() {
	document.querySelectorAll('.dropdown').forEach(function (dropdownWrapper) {
		const dropdownBtn = dropdownWrapper.querySelector('.dropdown__button');
		const dropdownList = dropdownWrapper.querySelector('.dropdown__list');
		const dropdownItems = dropdownList.querySelectorAll('.dropdown__list-item');
		const dropdownInput = dropdownWrapper.querySelector('.dropdown__input_hidden');

		dropdownBtn.addEventListener('click', function () {
			dropdownList.classList.toggle('dropdown__list_visible');
			this.classList.toggle('dropdown__button_active');
		});

		dropdownItems.forEach(function (listItem) {
			listItem.addEventListener('click', function (e) {
				dropdownItems.forEach(function (el) {
					el.classList.remove('dropdown__list-item_active');
				});
				e.target.classList.add('dropdown__list-item_active');
				dropdownBtn.innerText = this.innerText;
				dropdownInput.value = this.dataset.value;
				dropdownInput.dispatchEvent(new Event('change'));
				if (this.dataset.value !== '0') dropdownBtn.classList.add('dropdown__button_active-change');
				else dropdownBtn.classList.remove('dropdown__button_active-change');
				dropdownList.classList.remove('dropdown__list_visible');
			});
		});

		document.addEventListener('click', function (e) {
			if (e.target !== dropdownBtn) {
				dropdownBtn.classList.remove('dropdown__button_active');
				dropdownList.classList.remove('dropdown__list_visible');
			}
		});

		document.addEventListener('keydown', function (e) {
			if (e.key === 'Tab' || e.key === 'Escape') {
				dropdownBtn.classList.remove('dropdown__button_active');
				dropdownList.classList.remove('dropdown__list_visible');
			}
		});
		let indexItem = 0;

		document.addEventListener('keydown', function (e) {
			if (e.key === 'ArrowDown') {
				if (dropdownList.classList.contains('dropdown__list_visible')) {
					e.preventDefault();
					if (indexItem === dropdownItems.length - 1) {
						indexItem = 0;
						dropdownItems[0].classList.add('dropdown__list-item_active');
						dropdownItems[dropdownItems.length - 1].classList.remove('dropdown__list-item_active');
					} else {
						indexItem++;
						dropdownItems[indexItem - 1].classList.remove('dropdown__list-item_active');
						dropdownItems[indexItem].classList.add('dropdown__list-item_active');
					}
					dropdownItems[indexItem].focus();
				}
			}
			if (e.key === 'ArrowUp') {
				if (dropdownList.classList.contains('dropdown__list_visible')) {
					e.preventDefault();
					if (indexItem === 0) {
						indexItem = dropdownItems.length - 1;
						dropdownItems[0].classList.remove('dropdown__list-item_active');
						dropdownItems[indexItem].classList.add('dropdown__list-item_active');
					} else {
						indexItem--;
						dropdownItems[indexItem + 1].classList.remove('dropdown__list-item_active');
						dropdownItems[indexItem].classList.add('dropdown__list-item_active');
					}
					dropdownItems[indexItem].focus();
				}
			}
			if (e.key === 'Enter') {
				const parentName = e.target.parentElement.dataset.value;
				if (dropdownInput.name === parentName) {
					const parentInput = document.querySelector('[name="' + parentName + '"]');
					dropdownItems[indexItem].classList.add('dropdown__list-item_active');
					dropdownBtn.innerText = dropdownItems[indexItem].innerText;
					parentInput.value = dropdownItems[indexItem].dataset.value;
					parentInput.dispatchEvent(new Event('change'));
					dropdownBtn.classList.remove('dropdown__button_active');
					dropdownList.classList.remove('dropdown__list_visible');
					dropdownBtn.classList.add('dropdown__button_active-change');
					if (dropdownItems[indexItem].dataset.value !== '0') dropdownBtn.classList.add('dropdown__button_active-change');
					else dropdownBtn.classList.remove('dropdown__button_active-change');
				}
			}
		});
	});
}
