export default function Upload() {
    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");

        dropZoneElement.addEventListener("click", (e) => {
            inputElement.click();
        });

        inputElement.addEventListener("change", (e) => {
            if (inputElement.files.length) {
                updateThumbnail(dropZoneElement, inputElement.files[0]);
            }
        });

        dropZoneElement.addEventListener("dragover", (e) => {
            e.preventDefault();
            dropZoneElement.classList.add("drop-zone--over");
        });

        ["dragleave", "dragend"].forEach((type) => {
            dropZoneElement.addEventListener(type, (e) => {
                dropZoneElement.classList.remove("drop-zone--over");
            });
        });

        dropZoneElement.addEventListener("drop", (e) => {
            e.preventDefault();

            if (e.dataTransfer.files.length) {
                inputElement.files = e.dataTransfer.files;
                updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
            }

            dropZoneElement.classList.remove("drop-zone--over");
        });
    });

    /**
     * Updates the thumbnail on a drop zone element.
     *
     * @param {HTMLElement} dropZoneElement
     * @param {File} file
     */
    function updateThumbnail(dropZoneElement, file) {
        let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");
        let uploadWrap = document.querySelector('.upload-input')
        let removeBtn = document.querySelector('.remove-file')

        // remove upload file
        document.getElementById('rest-upload').addEventListener('click', () => {
            document.getElementById("uploadCaptureInputFile").value = "";
            removeBlock(dropZoneElement.querySelector(".drop-zone__prompt"))
            uploadWrap.classList.add('upload-input')
            uploadWrap.classList.remove('input-upload')
            if (thumbnailElement) {
                thumbnailElement.remove()
                thumbnailElement = document.createElement("div");
                thumbnailElement.classList.add("drop-zone__prompt");
                dropZoneElement.appendChild(thumbnailElement);
                thumbnailElement.innerHTML = `
                    <span>
                        Выберите резюме/портфолио
                    </span>
                    или перетащите их сюда
                `
            }
        })

        // First time - remove the prompt
        removeBlock(dropZoneElement.querySelector(".drop-zone__prompt"))

        // First time - there is no thumbnail element, so lets create it
        if (!thumbnailElement) {
            thumbnailElement = document.createElement("div");
            thumbnailElement.classList.add("drop-zone__thumb");
            dropZoneElement.appendChild(thumbnailElement);
        }

        // size convert
        let fileSize;
        if(file.size.toString().length < 7) {
            fileSize = `${Math.round(+file.size.toString()/1024).toFixed(2)}kb`
        } else {
            fileSize = `${(Math.round(+file.size.toString()/1024)/1000).toFixed(2)}MB`
        }

        uploadWrap.classList.add('input-upload')
        uploadWrap.classList.remove('upload-input')
        thumbnailElement.innerHTML = `
            <img loading="lazy" aria-label="check" src="/assets/img/icons/check.svg" alt="">
            <span class="file-name">${file.name}</span>
            <span class="file-size">${fileSize}</span>
        `
    }
    function removeBlock(block) {
        if (block) {
            block.remove();
        }
    }
}
