export default function FormActions() {
	const resumeCheck = document.getElementById('resume');
	const notResumeBlock = document.querySelector('.form-resume__not_resume-wrapper');
	const resumeBlock = document.querySelector('.form-resume__resume');
	const addMoreButton = document.querySelector('.form-resume__add-more');
	if (resumeCheck) {
		resumeCheck.addEventListener('change', (e) => {
			if (resumeCheck.checked) {
				notResumeBlock.style.setProperty('display', 'none');
				addMoreButton.style.setProperty('display', 'none');
				resumeBlock.style.setProperty('display', 'block');
			} else {
				notResumeBlock.style.setProperty('display', 'block');
				addMoreButton.style.setProperty('display', 'block');
				resumeBlock.style.setProperty('display', 'none');
			}
		});
	}
	let ct = 0;
	const addExperience = document.getElementById('addExperience');
	if (addExperience) {
		addExperience.addEventListener('click', () => {
			ct++;
			let div1 = document.createElement('div');
			div1.id = 'experience' + ct;
			div1.innerHTML = document.getElementById('resume-fields-template').innerHTML;
			div1.classList.add('form-resume__not_resume');
			div1.querySelector('.form-resume__input--companyName').setAttribute('name', 'notResume[' + ct + '][companyName]');
			div1.querySelector('.form-resume__input--jobTitle').setAttribute('name', 'notResume[' + ct + '][jobTitle]');
			div1.querySelector('.form-resume__input--description').setAttribute('name', 'notResume[' + ct + '][description]');
			notResumeBlock.appendChild(div1);
		});
	}
}
