export default function ScrollTop() {
	const header = document.querySelector('header');
	const sidebarContainer = document.querySelector('.sidebar-container');
	const frostedGlass = document.querySelector('.frosted-glass');

	const scrollBtn = document.querySelector('.scroll-to-top-btn');

	const fixHeader = () => {
		let pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
		if (pageOffset > 1) {
			header.classList.add('pre-fixed');
		} else {
			header.classList.remove('pre-fixed');
		}

		if (pageOffset > window.innerHeight) {
			header.classList.add('fixed');
		} else {
			header.classList.remove('fixed');
		}
	};

	const fixSidebar = () => {
		let pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
		if (pageOffset > window.innerHeight) {
			sidebarContainer.classList.add('fixed');
		} else {
			sidebarContainer.classList.remove('fixed');
		}
	};

	const setFrostedGlass = () => {
		let windowWidth = window.innerWidth;
		let pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
		// if (pageOffset > window.innerHeight) {
		// 	frostedGlass.style.height = '90px';
		// 	if (sidebarContainer && windowWidth < 1024) {
		// 		frostedGlass.style.height = '270px';
		// 	}
		// } else {
		// 	frostedGlass.style.height = '0';
		// }
	};

	if (scrollBtn) {
		let scrollToTop = () => {
			window.scroll({
				top: 0,
				behavior: 'smooth',
			});
		};

		scrollBtn.onclick = scrollToTop;

		window.addEventListener('scroll', function () {
			const vacanciesTitle = document.getElementById('vacancies-title');
			const popupSend = document.querySelector('.popup-vacancies__wrap');
			const vacancySendButton = document.querySelector('.vacancy-page__respond');
			let pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
			if (pageOffset !== 0) {
				scrollBtn.style.opacity = '1';
				if (pageOffset > 350) {
					if (popupSend) {
						popupSend.style.opacity = '1';
					}
				}
				if (vacancySendButton) vacancySendButton.classList.add('vacancy-page__respond--show');
			} else {
				scrollBtn.style = '';
				if (popupSend) {
					popupSend.style.opacity = '0';
				}
				if (vacancySendButton) vacancySendButton.classList.remove('vacancy-page__respond--show');
			}
		});
	}

	if (header) {
		window.addEventListener('scroll', fixHeader);
		window.addEventListener('resize', fixHeader);
		document.addEventListener('ready', fixHeader);
	}

	if (sidebarContainer) {
		if (frostedGlass) {
			document.querySelector('.sidebar-content').append(frostedGlass);
		}
		// window.addEventListener('scroll', fixSidebar);
		// window.addEventListener('resize', fixSidebar);
		// document.addEventListener('ready', fixSidebar);
	}

	if (frostedGlass) {
		window.addEventListener('scroll', setFrostedGlass);
		window.addEventListener('resize', setFrostedGlass);
		document.addEventListener('ready', setFrostedGlass);
	}
}
