export default function Menu() {
    const windowInnerWidth = window.innerWidth
    const navToggler = document.querySelector('.nav-toggler');
    const navTogglerMobile = document.querySelector('.sidebar-content .nav-toggler');
    const navMenu = document.querySelector('.site-navbar__link-wrap');
    const navMenuAside = document.querySelector('.sidebar-container');
    const navMenuMobile = document.querySelector('.sidebar-content .site-navbar__link-wrap');
    const navLinks = document.querySelectorAll('.site-navbar__link a');
    const body = document.getElementsByTagName('body')
    allEventsListener();

    function allEventsListener() {
        if(windowInnerWidth <= 1024 && navTogglerMobile) {
            navTogglerMobile.addEventListener('click', togglerClick)
        }
        navToggler.addEventListener('click', togglerClick);
        navLinks.forEach( elem => elem.addEventListener('click', navLinkClick));
    }

    function togglerClick() {
        if(windowInnerWidth <= 1024 && navTogglerMobile) {
            navTogglerMobile.classList.toggle('toggler-open')
            navMenuMobile.classList.toggle('open');
        }
        if(navMenuAside) {
            navMenuAside.classList.toggle('open-menu-aside');
        }
        navToggler.classList.toggle('toggler-open');
        navMenu.classList.toggle('open');
        body[0].classList.toggle('not-scroll');
    }

    function navLinkClick() {
        if(navMenu.classList.contains('open')) {
            navToggler.click();
            if(windowInnerWidth <= 1024 && navTogglerMobile) {
                navTogglerMobile.click();
            }
        }
    }
}
