export default function ActionButtons() {
    const sendBtn = document.getElementById('send-button-close')
    const popup = document.getElementById('popup-mobile')
    const questionBtn = document.getElementById('send-button-question')

    if(sendBtn) {
        sendBtn.addEventListener('click', () => {
            popup.classList.add('close');
            questionBtn.style.setProperty('display', 'block')
        })
    }
    if(questionBtn) {
        questionBtn.addEventListener('click', () => {
            popup.classList.remove('close');
            questionBtn.style.setProperty('display', 'none')
        })
    }
}
